/*••••••••••••••••••••••••••••••••••••••••••••••
     Widget Content LightGallery
     ••••••••••••••••••••••••••••••••••••••••••••••*/

// eslint-disable-next-line no-unused-vars
import lgThumbnail from "lightgallery/plugins/thumbnail";
// eslint-disable-next-line no-unused-vars
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

$(".widget-gallery").each((index, element) => {
  // eslint-disable-next-line no-undef
  lightGallery(element, {
    licenseKey: "7D9A64F4-A3EF4345-AC641546-E9A94647",
    // plugins: [lgZoom, lgThumbnail],
  });
});

$(".widget-video").each((index, element) => {
  // eslint-disable-next-line no-undef
  lightGallery(element, {
    licenseKey: "7D9A64F4-A3EF4345-AC641546-E9A94647",
    plugins: [lgVideo],
    autoplayVideoOnSlide: true,
  });
});
